/*
** Service  End-Point Configuration
*/

console.log('Loading configuration based on hostname directive: %s', window.location.hostname.toLowerCase())

var  service_endpoints = {
  baseurl_apm: process.env.REACT_APP_BASEURL_APM ? process.env.REACT_APP_BASEURL_APM : 'http://10.0.0.7:8090'
  , baseurl_cms: process.env.REACT_APP_BASEURL_CMS ? process.env.REACT_APP_BASEURL_CMS : 'http://NOTUSEDANYMORE'
  , baseurl_geo: process.env.REACT_APP_BASEURL_GEO ? process.env.REACT_APP_BASEURL_GEO : 'http://10.0.0.7:8082'
  , baseurl_ups: process.env.REACT_APP_BASEURL_UPS ? process.env.REACT_APP_BASEURL_UPS : 'http://10.0.0.7:8081'
  , baseurl_sqm: process.env.REACT_APP_BASEURL_SQM ? process.env.REACT_APP_BASEURL_SQM : 'http://10.0.0.7:8084'
  , baseurl_consent: process.env.REACT_APP_BASEURL_CONSENT ? process.env.REACT_APP_BASEURL_CONSENT : 'http://10.0.0.7:8083'
  , baseurl_keycloak: process.env.REACT_APP_BASEURL_KEYCLOAK ? process.env.REACT_APP_BASEURL_KEYCLOAK : 'http://10.0.0.7:7080'
  , baseurl_metering: process.env.REACT_APP_BASEURL_METERING ? process.env.REACT_APP_BASEURL_METERING : 'http://NOTUSEDANYMORE'
  , baseurl_notification: process.env.REACT_APP_BASEURL_NOTIFY ? process.env.REACT_APP_BASEURL_NOTIFY : 'http://10.0.0.7:8095'
  , baseurl_druid: process.env.REACT_APP_BASEURL_PINPOINT ? process.env.REACT_APP_BASEURL_PINPOINT : 'http://10.0.0.7:9091'
  , baseurl_rqe: process.env.REACT_APP_BASEURL_REPORTS_QUERYENGINE ? process.env.REACT_APP_BASEURL_REPORTS_QUERYENGINE : 'http://10.0.0.7:4000'
  , baseurl_reportsmanager: process.env.REACT_APP_BASEURL_REPORTS_MANAGER ? process.env.REACT_APP_BASEURL_REPORTS_MANAGER : 'http://10.0.0.7:4001'
  , baseurl_reportstemplate: process.env.REACT_APP_BASEURL_REPORTS_TEMPLATE ? process.env.REACT_APP_BASEURL_REPORTS_TEMPLATE : 'http://10.0.0.7:5488'
  , baseurl_spreadmap: process.env.REACT_APP_BASEURL_SPREADMAP ? process.env.REACT_APP_BASEURL_SPREADMAP : 'http://10.1.0.11:8000'
  , baseurl_trigger: process.env.REACT_APP_BASEURL_TRIGGER ? process.env.REACT_APP_BASEURL_TRIGGER : 'http://10.0.0.7:8095'
  , baseurl_geomarker: process.env.REACT_APP_BASEURL_GEOMARKER ? process.env.REACT_APP_BASEURL_GEOMARKER : 'http://10.0.0.7:9091'
}

/*
** Helper function
*/
Array.prototype.contains = function (obj) {
  return this.indexOf(obj) > -1
}

/*
** Set Proxy Configuration if HTTP Request Domain Name matches the following...
*/
if (
  ['gitex.eureka.ai', 'demo.eureka.ai', 'airtel.eureka.ai', 'devint.eureka.local'].contains(
    window.location.hostname.toLowerCase()
  )
) {
  console.log('[*] Using Proxy Configuration...')
  for (var key in service_endpoints) {
    if (service_endpoints.hasOwnProperty(key) && (key != 'baseurl_keycloak')) {
      service_endpoints[key] = '/'
    }
  }
}
console.log('[*] Service-Endpoint Configuration:')
for (var key in service_endpoints) {
  if (service_endpoints.hasOwnProperty(key)) {
    console.log(key, service_endpoints[key])
  }
}

var config = {
  // Landing Page could be one of pinpoint, explorer, splash, instantcheck, matchbox and some more.
  LandingPage: 'pinpoint',
  Auth: {
    Keycloak: {
      realm: 'spectrum',
      url: service_endpoints['baseurl_keycloak'] + '/auth',
      'ssl-required': 'external',
      clientId: 'spectrum-ux',
      'public-client': true,
      'verify-token-audience': true,
      'use-resource-role-mappings': true,
      'confidential-port': 0
    }
  },
  Dashboard: {
    enabled: true,
    reportsQueryEngineUrl: service_endpoints['baseurl_rqe'],
    reportsManagerUrl: service_endpoints['baseurl_reportsmanager'],
    CUBEJS_TOKEN:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1ODcwNDIzODEsImV4cCI6MTU4NzEyODc4MX0.nZC3KXN157NAJVKc4b9UWI0YnExEChbeRqMcx007Sbg',
    segments: { Audience: 'user_profile_indosat_small_18_dev_02' },
    dashboardFromTemplate:
      '/services/dashboard/rest/latest/templates/dashboard/create',
    templateFromCategory: '/services/dashboard/rest/latest/templates/category',
    template: '/services/dashboard/rest/latest/templates',
    allTemplates: '/services/dashboard/rest/latest/templates/all-templates',
    allCategories: '/services/dashboard/rest/latest/templates/all-categories',
    reportFromDashboard: '/services/reports/rest/latest/report/data',
    dashboard: '/services/dashboard/rest/latest',
    dashboardForUser: '/services/dashboard/rest/latest/user',
    saveReport: '/services/reports/rest/latest/report',
    sendEmail: '/services/dashboard/rest/latest/email',
    favoriteDashboard: '/services/dashboard/rest/latest/favorite'
  },
  PinPoint: {
    url_sormas: 'http://10.0.0.7:6080/sormas-ui/login#!cases/',
    urlDruid_API: service_endpoints['baseurl_druid'] + '/services/pinpoint/rest/v1/getData',
    urlDruidProfile_API: service_endpoints['baseurl_druid'] + '/services/pinpoint/rest/v2/profile',
    urlDruidV2_API: service_endpoints['baseurl_druid'] + '/services/pinpoint/rest/v2',
    urlCubeProfile_API: service_endpoints['baseurl_cube'] + '/cubejs-api/v1/load',
    urlElastic_Hexo: service_endpoints['baseurl_ups'] + '/services/ups/res/latest/geo/h3/search',
    urlDruid_SQL: service_endpoints['baseurl_druid'] + '/eureka/druid/v1/sql/getData',
    urlOpenStreetMap:
      'https://api.maptiler.com/maps/bright/{z}/{x}/{y}.png?key=Zr39nwGNk6dCteI7sZ6W',
    urlMapTiler: 'https://api.maptiler.com/maps/',
    paramMapTiler: '/256/{z}/{x}/{y}.png?&key=Zr39nwGNk6dCteI7sZ6W',
    urlElastic_Geo:
      service_endpoints['baseurl_ups'] + '/eureka/ups/elasticSearch/geo_json_malaysia/_search',
    urlElastic_ndb: service_endpoints['baseurl_ups'] + '/eureka/ups/elasticSearch/ref_ndb/_search',
    urlGeomarker: service_endpoints['baseurl_geomarker'] + '/services/pinpoint/rest/v2',
    urlNetwork:
      service_endpoints['baseurl_geomarker'] + '/services/pinpoint/rest/v2/geomarker-network',
    urlBoundaries: service_endpoints['baseurl_geo'] + '/services/geo/res/v1/geo/h3',
    urlGeoDruidSearch:
      service_endpoints['baseurl_geo'] + '/service/geo/rest/latest/geo/movement/search',
    urlGeoPoiExposureCount:
      service_endpoints['baseurl_geo'] + '/service/geo/rest/latest/poi/exposurecount',
    urlHexagonDetails: service_endpoints['baseurl_geo'] + '/services/geo/res/v1/geo/h3/hexagon',
    urlGeoServiceGraphQl: service_endpoints['baseurl_geo'] + '/graphql',
    urlSpreadMap: service_endpoints['baseurl_spreadmap'] + '/v1',
    urlSubscribe: service_endpoints['baseurl_trigger'] + '/services/airflow/rest/v1/trigger',
    useDAUMinus: 2,
    useAreaMeasurement: false,
    useSubAreaBorder: false,
    useCurrentDate: false,
    dateProfileStart: '2024-03-01',
    dateProfile: '2024-03-31',
    //dateProfileStart: '2023-01-01',
    //dateProfile: '2023-01-31',
    datePeriodDiseaseStart: '2020-05-01',
    datePeriodDisease: '2020-05-30',
    datePeriodPOI: '2020-02-16/2020-02-17',
    dsUserPOI: 'ftr_user_poi_daily_indonesia_covid19',
    dsUserHexagon: 'ftr_user_hexagon_daily_indonesia_covid19',
    dsUserExposureScore: 'ftr_user_health_scores_indonesia_covid19',
    dsUserEsiEmi: 'emi_subs_agg_indonesia_covid19',
    dsHexagonEsiEmi: 'emi_hex_agg_indonesia_covid19',
    contactTraceConfig: {
      resolution: 10,
      hexagonColumnName: 'hexagon_id_10',
      granularity: 'day',
      defaultDateRange: ['2020-01-01', '2020-01-08'],
      dwellTimeFilter: 1.0
    },
    dsCatPOI: 'ref_poi_hexo_jakarta',
    dsDashboard: 'db_disease_outbreak_indonesia_covid19',
    dbHexagonsDeseaseOutbreak: 'db_hexagon_disease_outbreak_indonesia_covid19',
    defaultResolution: 9,
    initBoundaryRes: 8,
    shapeFile: 'indonesia-centroid.geojson',
    mapStart: [24.774265, 46.738586],
    dashboardStart: [-1.72339668, 135.18142891],
    zoomLevel: 6,
    landingHeatmap: true,
    useFeatureEmiasi: false,
    useUserExposure: true,
    useFeatureCovidSpread: true,
    useFeaturePeopleMovement: true,
    useFeatureCaseManagement: false,
    country: 'indonesia',
    metric_granularity: 'province',
    dimension: 'province',
    provinceLabel: 'province',
    districtLabel: 'district',
    districtKey: 'district',
    subDistrictLabel: 'sub_district',
    villageLabel: 'village',
    subDistrictKey: 'subDistrict',
    villageKey: 'village',
    districtGroupLabel: 'District',
    subDistrictGroupLabel: 'Sub District',
    villageGroupLabel: 'Village',
    primaryTerritoryPlaceholder: 'Select District',
    secondaryTerritoryPlaceholder: 'Select Sub District / Village',
    useDashboardHourly: false,
    useSubdistrictLevel: true,
    useVillageLevel: true,
    useGeojsonZip: true,
    initialLandingArea: {
      label: 'JAKARTA PUSAT',
      value: 'JAKARTA PUSAT_133',
      key: 'district',
      provinceValue: 'DKI JAKARTA_16'
    },
    showForecast: true,
    useSpreadMapTopLevel: 'Province',
    showMovementMetric: true,
    provinceGroupLabel: 'Province',
    initialLandingDefaultZoom: 12,
    useDensityTopLevel: true,
    useDensityInterval: false,
    useSecondDropdownArea: true,
    showDownloadAllCSVNetworkBtn: true,
    useSpecificProfince: ['DKI JAKARTA', 'JAWA BARAT', 'JAWA TIMUR'],
    useSpecificDistrict: [
      {
        province: 'DKI JAKARTA',
        districts: ['JAKARTA BARAT', 'JAKARTA PUSAT']
      },
      {
        province: 'JAWA BARAT',
        districts: ['BANDUNG', 'BEKASI', 'BOGOR']
      }
    ],
    doohMetrics: {
      impression: {
        title: 'Total Monthly',
        subtitle: 'Impression'
      },
      uniqueReach: {
        title: 'Total Monthly',
        subtitle: 'Unique Reach'
      },
      avgUniqueReach: {
        title: 'Daily Avg',
        subtitle: 'Unique Reach (Monhtly)',
        subtitleWeekdays: 'Unique Reach (Weekdays)',
        subtitleWeekend: 'Unique Reach (Weekend)'
      },
      avgDwellTime: {
        title: 'Average',
        subtitle: 'Dwell Time (Hour)'
      },
      avgFrequency: {
        title: 'AVG Frequency',
        subtitle: 'Per Month'
      },
      hourlyUniqueReach: {
        title: 'Hourly Unique Reach',
        subtitle: 'by Day Part'
      },
      avgTraffic: {
        title: 'Average Traffic',
        subtitle: 'by Day Part'
      },
      demographic: {
        title: 'Audience Demographic Profiling'
      },
      reachSaturation: {
        title: 'Reach Saturation Analysis',
        subtitle: ''
      },
      monthlyUniqueReachHome: {
        title: 'Monthly Unique Reach',
        subtitle: 'Home Location'
      },
      monthlyUniqueReachWork: {
        title: 'Monthly Unique Reach',
        subtitle: 'Work Location'
      },
      frequencyAnalysis: {
        title: 'Frequency Analysis',
        subtitle: 'In a Month'
      },
      frequencyBucket: {
        title: 'Frequency Bucket by Unique Reach',
        subtitle: 'per Month by Top 10 Home Location'
      },
      audienceLocality: {
        title: 'Audience Locality Profiling',
        subtitleHome: 'Top 10 Home Location',
        subtitleWork: 'Top 10 Work Location'
      },
      poiLevel2Name: {
        title: 'Count of POI',
        subtitle: 'On This Location'
      }
    },
    metricsOnTabs: true,
    tabsMetrics: [
      {
        title: 'Impressions',
        metrics: ['avgTraffic']
      },
      {
        title: 'User Reach',
        metrics: ['reachSaturation']
      },
      {
        title: 'Demographic',
	metrics: ['demographic', 'monthlyUniqueReachHome', 'monthlyUniqueReachWork']
      }
    ],
    disableHighResolution: false,
    usePermissionInReportDOOH: true,
    chartType: {
      poiLevel2Name: 'horizontalBar' // Default to Pie Chart, leave it blank to show default chart
    },
    ksaDemo: true
  },
  Explorer: {
    primaryIndexName: 'user_profile_indosat_small_18_dev_02',
    segmentQueryManager: {
      url: service_endpoints['baseurl_sqm'],
      namedQuery: '/services/sqm/rest/latest/namedQuery',
      getAllNamedQuery: '/services/sqm/rest/latest/getAllNamedQuery',
      getNamedQueryForIndex: '/services/sqm/rest/latest/namedQuery/index'
    },
    upsManager: {
      url: service_endpoints['baseurl_ups'],
      elasticSearch: '/services/ups/rest/latest/users/elasticSearch',
      getWhiteList: '/services/ups/rest/latest/users/getWhiteList',
      getLookalikeWhiteList: '/services/ups/rest/latest/lookalike/getWhiteList'
    }
  },
  MatchBox: {
    campaignService: {
      url: service_endpoints['baseurl_cms'] + '/eureka/api/campaign/v1'
    },
    telcoService: {
      url: service_endpoints['baseurl_cms'] + '/eureka/api/telco/v1'
    },
    regionService: {
      url: service_endpoints['baseurl_cms'] + '/eureka/api/country/v1'
    },
    clientService: {
      url: service_endpoints['baseurl_cms'] + '/eureka/api/client/v1'
    }
  },
  InstantCheck: {
    primaryIndexName: 'user_profile_indosat_small_18_dev_02',
    creditScoreMetadataIndexName:
      'credit_score_metadata_indosat_small_1.8_dev_0.1',
    addressIndexName: 'user_address_indosat_small_1.8_dev_0.1',
    upsElasticSearch: {
      url: service_endpoints['baseurl_ups'] + '/services/ups/rest/latest/users/elasticSearch'
    },
    singleScoreService: {
      url: service_endpoints['baseurl_ups'] + '/services/creditscore/rest/latest'
    },
    batchScoreService: {
      url: service_endpoints['baseurl_ups'] + '/services/creditscore/rest/latest/batch'
    },
    getAddressService: {
      url: service_endpoints['baseurl_ups'] + '/services/addresscheck/rest/latest'
    },
    getRiskScoreProfileService: {
      url: service_endpoints['baseurl_ups'] + '/services/creditscore/rest/latest/profile'
    },
    getAddressProfileService: {
      url: service_endpoints['baseurl_ups'] + '/services/addresscheck/rest/latest/profile'
    }
  },
  AccountPermissionManager: {
    manageAccounts: {
      url: service_endpoints['baseurl_apm'] + '/services/uam/rest/v2/account'
    },
    manageUsers: {
      url: service_endpoints['baseurl_apm'] + '/services/uam/rest/v2/user'
    },
    manageProducts: {
      url: service_endpoints['baseurl_apm'] + '/services/uam/rest/v2/product/backend'
    },
    manageRoles: {
      url: service_endpoints['baseurl_apm'] + '/services/uam/rest/v2/role'
    }
  },
  Metering: {
    indexName: 'ups-service-logs',
    meteringService: {
      url: service_endpoints['baseurl_metering'] + '/services/ups/rest/latest/metering'
    }
  },
  DefaultService: {
    notificationService: {
      enabled: true,
      url: service_endpoints['baseurl_notification'],
      getNotificationUrl: '/services/notification/rest/v1/alert'
    }
  },
  triggerService: {
    url: service_endpoints['baseurl_trigger'] + '/services/airflow/rest/v1/trigger'
  },
  triggerTemplate: {
    url: service_endpoints['baseurl_reportstemplate'] + '/odata/templates'
  },
  reportsManagerService: {
    url: service_endpoints['baseurl_geomarker'] + '/services/pinpoint/rest/v2/geo-marker/'
  },
  ConsentManager: {
    getConsent: service_endpoints['baseurl_consent'] + '/services/consent/rest/latest/userid/'
  },
  Verification: {
    startMonth: '2020-08-01',
    bulkRequestDelay: 50,
    urlDruid_API: service_endpoints.baseurl_geo + '/services/geo/rest/latest',
    instaCheck: service_endpoints.baseurl_ups + '/services/ups/validate/rest/latest',
    instaCheckCoordinate: service_endpoints.baseurl_ups + '/services/ups/validate/rest/latest/location/validate_coordinates',
    consentDateInputFormat: 'DD/MM/YYYY HH:mm',
    bulkUploadLimit: 5000
  }
}
export default config
